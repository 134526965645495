import { css } from '@emotion/react';

import { colors, typography } from 'styles/theme';

import { fontFacesCss } from './fontFaces';

const browserBaseFontSize = 16;

const rootCss = css`
  :root {
    /* Font sizes */
    --font-xs: 1.4rem;
    --font-sm: 1.6rem;
    --font-md: 1.8rem;
    --font-lg: 2rem;
    --font-xl: 2.4rem;
    --font-2xl: 2.6rem;
    --font-3xl: 2.8rem;
    --font-4xl: 3.2rem;
    --font-5xl: 4.2rem;
  }
`;

export const globalCss = css`
  ${rootCss}
  ${fontFacesCss}

  /**
   * Box model like it should be:
   * http://www.paulirish.com/2012/box-sizing-border-box-ftw/
   */
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /**
   * Reset
   */
  html,
  body {
    margin: 0;
    padding: 0;
    font-weight: 300;
    letter-spacing: 0.2px;
  }

  html.no-vertical-scroll {
    height: 100%;
    overflow-y: hidden;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: ${colors.darkPurple};
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: none;
  }

  /**
   * Font sizing strategy making 1rem equal 10px for easy usage
   * e.g. 1.6rem = 16px
   */
  :root {
    /* e.g. (10 / 16) * 100 = 62.5% */
    font-size: ${(typography.rootFontSize / browserBaseFontSize) * 100}%;
  }

  /**
   * Default font settings for all generic text
   */
  body {
    font-family: ${typography.fontPrimary};
    font-size: 1.8rem;
    font-weight: 300;
    /* Prevent scroll-bar from showing when positioning elements outside (to the page's right) */
    overflow-x: hidden;
    color: ${colors.textColor};
  }

  /**
   * Fix default input font styles not taken from body styles
   */
  input {
    font-size: inherit;
    font-weight: inherit;
  }

  /**
   * Makes the hidden attribute work even when an element has display: flex
   * http://lists.w3.org/Archives/Public/public-whatwg-archive/2014May/0001.html
   */
  [hidden] {
    display: none !important;
  }

  b,
  strong {
    font-weight: 500;
  }

  /**
   * A fix for WebKit browsers as inputs behave differently on it.
   * Using the text-fill-color, sets coloring right.
   */
  input::placeholder,
  input[disabled] {
    -webkit-text-fill-color: currentColor;
  }
`;
