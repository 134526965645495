import 'normalize.css';

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { Global } from '@emotion/react';
import { ThemeProvider } from '@material-ui/core/styles';

import { reporter } from 'lib/reporter';
import { onClientEntry } from 'lib/onClientEntry';
import { LocaleProvider } from 'lib/localeProvider';

import { globalCss } from 'styles/global';
import muiTheme from 'styles/muiTheme';

const DynamicCookieConsent = dynamic(
  () =>
    import(
      /* webpackChunkName: "DynamicCookieConsent" */
      'components/shared/CookieConsent/CookieConsent'
    ),
  { ssr: false }
);

// Setup error handling, must happen before anything else
reporter.init();
// Init client stuff before we render anything with React
onClientEntry();

/**
 * This component wraps all pages.
 * Good for global configurations.
 *
 * https://nextjs.org/docs/advanced-features/custom-app
 */
function CustomApp(props) {
  const { Component, pageProps } = props;
  const pathname =
    typeof window !== 'undefined' ? window?.location?.pathname : null;

  if (pathname === '/uk') {
    location.href = 'https://minuteful.com/uk/uti/';
  } else if (pathname === '/nhs' || pathname === '/nhs-first') {
    location.href = 'https://minuteful.com/uk/uti/nhs';
  }

  // Taken from:
  // https://github.com/zeit/next.js/blob/canary/examples/with-sentry-simple/
  // Workaround for https://github.com/zeit/next.js/issues/8592
  const { err } = props;

  const modifiedPageProps = { ...pageProps, err };

  return (
    <>
      <Global styles={globalCss} />

      <LocaleProvider>
        <ThemeProvider theme={muiTheme}>
          <Component {...modifiedPageProps} />
          <DynamicCookieConsent />
        </ThemeProvider>
      </LocaleProvider>
    </>
  );
}

CustomApp.propTypes = {
  /** The page component */
  Component: PropTypes.elementType.isRequired,
  /**
   * Passed from Next.js page methods.
   * @see
   * https://nextjs.org/docs/basic-features/data-fetching
   * https://nextjs.org/docs/api-reference/data-fetching/getInitialProps
   */
  pageProps: PropTypes.object.isRequired,
  /**
   * Next,js catches errors with an ErrorBoundary and if `getInitialProps`
   * throws (more details in `_error.js`).
   */
  err: PropTypes.string,
};

export default CustomApp;
