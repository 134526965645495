/**
 * This code runs before we render anything with React.
 */
import queryString from 'query-string';
import Router from 'next/router';

import { trackPageView } from 'lib/tracking';

import { AppStore } from 'stores/AppStore';

import { localStorage } from './storage';

function handleQueryParams() {
  const query = queryString.parse(window.location.search);
  const partnerName = query.partner;

  if (partnerName) {
    AppStore.setPartner(partnerName);
  }

  /**
   * Support custom Geotargets via Google AdWords:
   * https://developers.google.com/adwords/api/docs/appendix/geotargeting
   *
   * We use this Criteria ID to get location details (from our server API)
   * and center the StoreLocator map to the target location.
   */
  const locationCriteriaId = query.location_geo || query.location_int || null;

  if (locationCriteriaId) {
    localStorage.setItem('locationCriteriaId', locationCriteriaId);
  }
}

function trackRouteChange() {
  /**
   * We want to fire page view on each client navigation route change
   * https://nextjs.org/docs/api-reference/next/router#routerevents
   */
  Router.events.on('routeChangeComplete', url => {
    /**
     * routeChangeComplete fires when there's a URL query.
     * Therefore, in order to prevent page track from firing twice,
     * we disable it if the URL has a query.
     *
     * @see
     * https://github.com/vercel/next.js/issues/11639
     */
    if (url.includes('?')) {
      return;
    }

    trackPageView();
  });
}

export const onClientEntry = () => {
  if (!process.browser) {
    return;
  }

  handleQueryParams();
  trackRouteChange();
};
