import { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { locales } from 'locale/locale.config';

const LocaleContext = createContext();

function LocaleProvider({ children }) {
  const router = useRouter();
  const { locale: localeCode } = router.query;

  const data = {
    localeCode,
    localeLang: locales[localeCode]?.lang,
  };

  return (
    <LocaleContext.Provider value={data}>{children}</LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.object,
};

function useLocale() {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return context;
}

export { LocaleProvider, useLocale };
