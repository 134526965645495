import { createMuiTheme } from '@material-ui/core/styles';

import { typography } from 'styles/theme';

/**
 * Material UI customization using a theme.
 * Changing the theme configuration variables is the most
 * effective way to match Material-UI to our needs.
 *
 * @see
 * [Material UI Theme](https://material-ui.com/customization/theming)
 *
 * @see
 * [Material Default Theme](https://material-ui.com/customization/default-theme/)
 */
const muiTheme = createMuiTheme({
  typography: { fontFamily: typography.fontPrimary },
});

export default muiTheme;
