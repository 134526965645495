import Cookies from 'js-cookie';

export const COOKIES_CONSENT_COOKIE_NAME = 'cookiesConsent';

/**
 * Checkout steps mapped into step numbers.
 * Step numbers are from marketing's narrative, and shouldn't change.
 */
export const CHECKOUT_STEPS = {
  VERIFY_PHONE_NUMBER: 1,
  VERIFY_CODE: 2,
  PERSONAL_DETAILS: 3,
  SHIPPING: 4,
  PAYMENT: 5,
};

export const TRACK_EVENT_NAMES = {
  CHECKOUT_STEP_COMPLETE: 'Completed Checkout Step',
  CHECKOUT_STEP_VIEW: 'Viewed Checkout Step',
  SHIPPING_OUTSIDE_DELIVERY_AREA: 'out of delivery area',
  SHIPPING_FAILED: 'invalid address',
  ORDER_COMPLETED: 'Order Completed',
  FAQ: 'FAQ Click',
  STORE_PIN: 'Store Pin',
  SHOW_MY_LOCATION: 'Show My Location',
  STORE_SEARCH: 'Search',
  LOGO: 'Logo',
  CONSULTATION_NOTES: 'consultation notes',
  QUESTIONNAIRE_INCLUDED: 'questionnaire - included',
  QUESTIONNAIRE_EXCLUDED: 'questionnaire - excluded',
  QUESTIONNAIRE_DIALOG: 'questionnaire popup',
  PREVIOUS_CTA: 'previous',
  IOS_APP: 'ios app',
  ANDROID_APP: 'android app',
  HEALTHY: 'healthy.io',
  CONTACT_US: 'contact us form',
  INVALID_CODE: 'invalid code',
  INVALID_PHONE_NUMBER: 'invalid phone',
  VERIFY_PHONE_NUMBER: 'phone insertion',
  VERIFY_CODE: 'code insertion',
  RESEND_CODE: 'Resend code',
  PAYMENT_FAILED: 'Payment failed',
  LOBBY_HERO_PROMO_CLICK: 'Main Article Click',
  INFO_CENTER_NAVBAR_CLICK: 'Tab Navigation Click',
  SHIPPING_OPTION_SELECT: 'Shipping Option Selected',
  REVIEW_CLICK: 'Review click',
};

export const TRACK_CATEGORIES = {
  CHECKOUT_CLICK: 'checkout click',
  CTA_CLICK: 'cta click',
  LINK_CLICK: 'link click',
  LOGO_CLICK: 'logo click',
  SOCIAL_CLICK: 'social click',
  FORM_SUBMIT: 'form submit',
  QUESTIONNAIRE: 'questionnaire',
  STORE_LOCATOR: 'store locator',
  FAQ_QUESTION: 'faq question',
  APP_STORE_CLICK: 'app store click',
  ERROR: 'error',
  POPUP: 'popup',
};

export const TRACK_LABELS = {
  HEADER: 'header',
  PAGE: 'page',
  FOOTER: 'footer',
  POPUP: 'popup',
  OPT_OUT: 'opt-out',
  APPLE_STORE: 'ios appstore',
  GOOGLE_STORE: 'google play',
  BOTTOM_PAGE: 'bottom page',
  MOBILE_SCROLL: 'mobile scroll',
  MOBILE_MENU: 'mobile menu',
};

/** Check if the client disabled event tracking. */
function isTrackingDisabled() {
  return Cookies.get(COOKIES_CONSENT_COOKIE_NAME) === 'declined';
}

/**
 * Record a page view whenever a user sees a page of the website.
 *
 * @see
 * [Spec: Page](https://segment.com/docs/connections/spec/page/)
 *
 * @param {string} name Name of the page.
 * @param {object} properties Free-form dictionary of properties of the page.
 */
export function trackPageView(name, properties) {
  if (!isTrackingDisabled() && process.browser) {
    window.analytics?.page?.(name, properties);
  }
}

/**
 * Record any action that the user performs.
 *
 * @see
 * [Spec: Track](https://segment.com/docs/connections/spec/track/)
 *
 * @param {string} event Name of the action that a user has performed.
 * @param {object} properties Free-form dictionary of properties of the event.
 */
export function trackEvent(event, properties) {
  if (!isTrackingDisabled() && process.browser) {
    window.analytics?.track?.(event, properties);
  }
}

/**
 *  Ties a user to their actions and record traits about them.
 *
 * @see
 * [Spec: Identify](https://segment.com/docs/connections/spec/identify/)
 *
 * @param {string} userId Unique identifier for the user in our database.
 * @param {object} traits Free-form dictionary of traits of the user,
 *                        like email or name.
 */
export function identifyEvent(userId, traits) {
  if (!isTrackingDisabled() && process.browser) {
    window.analytics?.identify?.(userId, traits);
  }
}

/**
 *  Merge two user identities, connecting two sets of user data as one.
 *
 * @see
 * [Spec: Alias](https://segment.com/docs/connections/spec/alias/)
 *
 * @param {string} userId Unique identifier for the user in our database.
 */
export function aliasEvent(userId) {
  if (!isTrackingDisabled() && process.browser) {
    window.analytics?.alias?.(userId);
  }
}

export function trackBtnClick({ eventName, props }) {
  trackEvent(eventName, {
    category: TRACK_CATEGORIES.CTA_CLICK,
    ...props,
  });
}

export function trackLinkClick({ eventName, props }) {
  trackEvent(eventName, {
    category: TRACK_CATEGORIES.LINK_CLICK,
    ...props,
  });
}
