import Router from 'next/router';

import { AppStore } from 'stores/AppStore';

export const APP_LOCALES = {
  UK: 'uk',
  IE: 'ie',
  NHS: 'nhs',
  NHS_FIRST: 'nhs-first',
};

export const locales = {
  [APP_LOCALES.IE]: {
    label: 'Ireland',
    lang: 'en-IE',
  },
  [APP_LOCALES.UK]: { label: 'United Kingdom', lang: 'en-GB' },
  [APP_LOCALES.NHS]: { label: 'United Kingdom', lang: 'en-GB' },
  [APP_LOCALES.NHS_FIRST]: { label: 'United Kingdom', lang: 'en-GB' },
};

export const appLocales = AppStore.isDipUti
  ? [APP_LOCALES.UK, APP_LOCALES.NHS, APP_LOCALES.NHS_FIRST]
  : [APP_LOCALES.UK, APP_LOCALES.IE];

export const kitPurchaseLocales = AppStore.isDipUti ? [] : [APP_LOCALES.UK];

export const storeLocatorLocales = AppStore.isDipUti
  ? [APP_LOCALES.UK, APP_LOCALES.NHS, APP_LOCALES.NHS_FIRST]
  : /*
    NOTE: Using `appLocales` because the Dip UTI app points to
    velieve.io/uk/store-locator. This is a temporary situation and once
    it points to Dip UTI store locator, we should do -
    appLocales.filter(localeCode => !kitPurchaseLocales.includes(localeCode))
    */
    appLocales;

export const infoCenterLocales = AppStore.isDipUti ? [APP_LOCALES.UK] : [];

/**
 * Redirect to a given path with the current locale.
 *
 * @param {string} path
 */
async function setUrl(path, replace = false) {
  const { locale: localeCode } = Router.query;

  // If path is only "/", we remove it in order to prevent
  // a trailing slash on the locale.
  const urlPath = path === '/' ? '' : path;

  if (localeCode) {
    await Router[replace ? 'replace' : 'push'](
      `/[locale]${urlPath}`,
      `/${localeCode}${urlPath}`
    );
  }
}

async function replaceUrl(path) {
  await setUrl(path, true);
}

async function pushUrl(path) {
  await setUrl(path);
}

/**
 * Get app locale by country code, defaults to 'uk'.
 *
 * @param {string} countryCode
 * @returns {string} Locale
 */
function getLocale(countryCode) {
  const isLocaleSupported = appLocales.includes(countryCode);

  return isLocaleSupported ? countryCode : 'uk';
}

function changeLocale(localeCode) {
  const { asPath } = Router;
  const redirectTo = `/${localeCode}${asPath.slice(3)}`;

  window.location = redirectTo;
}

export const locale = { changeLocale, getLocale, replaceUrl, pushUrl };
