import { sessionStorage } from 'lib/storage';

const defaultPartner = 'general';

/**
 * Global state that's relevant for the whole website.
 *
 * IMPORTANT NOTE:
 * Not using MobX for this store for several reasons:
 * - This data is set during app init and doesn't change after that.
 * - This module is used across many pages, most of which really don't need the
 *   extra MobX dependency bloating their bundle size.
 */
export class AppStore {
  /** Determine if the website is Dip UTI */
  static isDipUti = process.env.DEPLOY_BRAND === process.env.BRANDS.diputi.id;

  static isProduction = process.env.VERCEL_GITHUB_COMMIT_REF === 'master';

  static isStaging = process.env.VERCEL_GITHUB_COMMIT_REF === 'staging';

  static isPreview = process.env.DEPLOY_TARGET === 'preview';

  /**
   * Used for showing specific content based on the target partner.
   * e.g. used in `StoreLocator` and `MapView > getPartnersByStoreVersion`.
   *
   * On the client can be initialized via a query param.
   * @see `onClientEntry`
   */
  static partner = sessionStorage.getItem('partner') ?? defaultPartner;

  /**
   * Save `partner` from a query param to make it available on AppStore.
   * We need this because the query params are removed on route change.
   * Also cache to `sessionStorage` to support page reload without the query
   * param in the URL.
   *
   * @param {string} partner e.g. boots, daylewis
   */
  static setPartner(partner) {
    AppStore.partner = partner ?? defaultPartner;

    sessionStorage.setItem('partner', AppStore.partner);
  }
}
