import { css } from '@emotion/react';

/**
 * woff2 - Super Modern Browsers
 * woff - Modern Browsers
 * ttf - Legacy Safari, Android, iOS (optional at this point)
 *
 * References:
 * - https://medium.com/clio-calliope/making-google-fonts-faster-aadf3c02a36d
 * - https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/webfont-optimization
 */
export const fontFacesCss = css`
  /* Gilroy-normal */
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Gilroy-normal'),
      url('/fonts/gilroy/gilroy-regular.woff2') format('woff2'),
      url('/fonts/gilroy/gilroy-regular.woff') format('woff'),
      url('/fonts/gilroy/gilroy-regular.ttf') format('truetype');
  }

  /* Gilroy-medium */
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Gilroy-medium'),
      url('/fonts/gilroy/gilroy-medium.woff2') format('woff2'),
      url('/fonts/gilroy/gilroy-medium.woff') format('woff'),
      url('/fonts/gilroy/gilroy-medium.ttf') format('truetype');
  }

  /* Gilroy-bold */
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Gilroy-bold'),
      url('/fonts/gilroy/gilroy-bold.woff2') format('woff2'),
      url('/fonts/gilroy/gilroy-bold.woff') format('woff'),
      url('/fonts/gilroy/gilroy-bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Tiempos';
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    src: local('tiempos-bold'),
      url('/fonts/tiempos/tiempos-bold.woff2') format('woff2'),
      url('/fonts/tiempos/tiempos-bold.woff') format('woff');
  }
`;
