export const colors = {
  purple: '#7f83dd',
  mildPurple300: '#d8d9f4',
  mildPurple: '#a3a6e5',
  darkPurple: '#545aa3',
  textColor: '#0f0238',
  sunYellow: '#f7d330',
  brightYellow: '#ffec82',
  brightYellow300: '#fff7d1',
  offWhite: '#f8f2e5',
  offWhite400: '#fcfaf5',
  darkOffWhite: '#e0d8ce',
  lightOffWhite: '#fbf8f2',
  veryLightOffWhite: '#fffdf8',
  pale: '#ffe6cc',
  brownGray: '#9c9c9c',
  softBlue: '#5895ff',
  black: '#000',
  white: '#fff',
  borderGray: '#ddd',
  inputPlaceholder: '#cfccd7',
  disabledInputGray: '#cfccd7',
  inputBorder: '#9c95a7',
  dangerRed: '#e23401',
  successGreenText: '#00a35a',
  successGreenBg: '#01e27d',
};

export const typography = {
  // `px` value, all CSS `rem` values will be relative to this value
  rootFontSize: 10,
  fontPrimary: 'Gilroy, system-ui, Arial, sans-serif',
  fontSerif: 'Tiempos, Gilroy, system-ui, Arial, sans-serif',
};

/* Content's grid maximum width */
export const gridMaxWidth = 940;

/* Content's grid padding */
export const gridPadding = 15;

/**
 * Screen breakpoints based on "popularity" guesstimates and statistics derived
 * from annualized monthly Google queries and some fuzzy math.
 *
 * @see
 * [Screen Size.es](http://screensiz.es/) (Using "Device width" for media-queries)
 */
export const breakpoints = {
  // Smallest mobile screen we support
  // Anything below that is unsupported
  mobile: 360,
  mobilePx: '360px',
  // Smallest tablet screen we support
  tablet: 768,
  tabletPx: '768px',
  // Smallest desktop screen we support
  desktop: 1280,
  desktopPx: '1280px',
};

export const tabletBreakpointRem = breakpoints.tablet / typography.rootFontSize;
export const desktopBreakpointRem =
  breakpoints.desktop / typography.rootFontSize;

export const zLayers = {
  behindAll: -1,
  base: 0,
  aboveAll: 1000,
};

/**
 * - `xs`: 1.4rem
 * - `sm`: 1.6rem
 * - `md`: 1.8rem
 * - `lg`: 2rem
 * - `xl`: 2.4rem
 * - `2xl`: 2.6rem
 * - `3xl`: 2.8rem
 * - `4xl`: 3.2rem
 * - `5xl`: 4.2rem
 */
export const fontSize = {
  xs: 'var(--font-xs)',
  sm: 'var(--font-sm)',
  md: 'var(--font-md)',
  lg: 'var(--font-lg)',
  xl: 'var(--font-xl)',
  '2xl': 'var(--font-2xl)',
  '3xl': 'var(--font-3xl)',
  '4xl': 'var(--font-4xl)',
  '5xl': 'var(--font-5xl)',
};
