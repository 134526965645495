// Aliased to `@sentry/browser` on client side
import * as Sentry from '@sentry/node';
import { ExtraErrorData } from '@sentry/integrations';

import { AppStore } from 'stores/AppStore';

/**
 * Setup Sentry
 * Will catch uncaught exceptions and unhandled Promise rejections automatically
 *
 * References:
 * https://docs.sentry.io/platforms/javascript/
 * https://docs.sentry.io/platforms/javascript/react/
 */

const allowSentry =
  !AppStore.isTest && (AppStore.isProduction || AppStore.isPreview);

const sentryOptions = {
  dsn: process.env.SENTRY_DSN_URL,
  integrations: [new ExtraErrorData()],
  environment: process.browser ? 'browser' : 'server',
  ignoreErrors: [
    'TypeError: cancelled',
    'Non-Error promise rejection captured with value: geoloc service failure',

    /**
     * A `fetch` promise will reject with a TypeError when a
     * network or CORS error is encountered.
     */
    'TypeError: Failed to fetch', // Chrome
    'TypeError: NetworkError when attempting to fetch resource', // FireFox
    'TypeError: Load failed', // Safari
  ],
  release: process.env.APP_VERSION,
};

function init() {
  if (!allowSentry) return;

  Sentry.init(sentryOptions);
}

/**
 * Send an error to Sentry
 *
 * @param {Error}  err                  The error to report
 * @param {Object} [additionalMetadata] Optional object with extra data
 */
function error(err, additionalMetadata = null) {
  if (!allowSentry) return;

  if (additionalMetadata) {
    Sentry.setExtra('metadata', JSON.stringify(additionalMetadata, null, 2));
  }
  Sentry.captureException(err);
}

export const reporter = { init, error };
